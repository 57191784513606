import { call, put } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";

import { normalize } from "normalizr";
import getBlogData from "../../../services/Blog";
import { fetchBlogDataSuccess, fetchBlogDataFailure } from "./Blog.action";
import blogSchema from "./Blog.schema";

export default function* fetchBlogSaga(): SagaIterator {
  try {
    const blog = yield call(getBlogData);
    yield put(fetchBlogDataSuccess(normalize(blog, [blogSchema])));
  } catch (err) {
    const error = "something went wrong!";
    yield put(fetchBlogDataFailure(error));
  }
}
