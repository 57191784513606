import React, { FC } from "react";
import { ScaleLoader } from "react-spinners";

import "./styles.scss";

const Loader: FC = () => (
  <div className="loader-wrap" data-testid="loader-wrap">
    <ScaleLoader height={150} width={8} color="#bf0c2b" loading />
  </div>
);

export default Loader;
