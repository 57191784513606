import React, { FC } from "react";
import * as Sentry from "@sentry/react";

import Layout from "./shared/components/layout";

const App: FC = () => (
  <>
    <Layout />
  </>
);
export default Sentry.withProfiler(App);
