import React, { FC } from "react";
import NavLinks from "../NavLinks";

import "./styles.scss";

interface Props {
  isActive: boolean;
  toggle: () => void;
}

const SideNav: FC<Props> = React.memo(({ isActive, toggle }: Props) => {
  return (
    <div className={isActive ? `side-nav active` : `side-nav close`}>
      <NavLinks toggle={toggle} />
    </div>
  );
});

export default SideNav;
