import { SagaIterator } from "redux-saga";
import { takeLatest } from "redux-saga/effects";
import ActionType from "../types";
import fetchBlogSaga from "../pages/Blog/container/Blog.saga";

const rootSaga = function* root(): SagaIterator {
  yield takeLatest(ActionType.FETCH_BLOG_REQUEST, fetchBlogSaga);
};

export default rootSaga;
