import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import "./styles.scss";

interface Props {
  toggle: () => void;
}

const NavLinks: FC<Props> = React.memo(({ toggle }: Props) => {
  return (
    <div className="navigation-links">
      <ul className="clearfix ">
        <li>
          <NavLink
            to="/"
            exact
            activeClassName="selected-link"
            onClick={toggle}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to={{
              pathname: "/about",
            }}
            exact
            activeClassName="selected-link"
            onClick={toggle}
          >
            About
          </NavLink>
        </li>
        <li>
          <NavLink
            to={{
              pathname: "/project",
            }}
            exact
            activeClassName="selected-link"
            onClick={toggle}
          >
            Project
          </NavLink>
        </li>
        <li>
          <NavLink
            to={{
              pathname: "/contact",
            }}
            exact
            activeClassName="selected-link"
            onClick={toggle}
          >
            Contact
          </NavLink>
        </li>
        {/* <li>
          <NavLink
            to={{
              pathname: "/blog",
            }}
            exact
            activeClassName="selected-link"
            onClick={toggle}
          >
            Blog
          </NavLink>
        </li> */}
      </ul>
    </div>
  );
});

export default NavLinks;
