import React, { FC, useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMobile,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";

import vasanthImg from "../../../assets/Vasanth_img.png";
import jsonData from "../../../constant.json";
import HamBurgerButton from "../../../atoms/HamBurgerButton";
import DarkModeToggleButton from "../../../atoms/DarkModeToggleButton";
import Particles from "./particle";
import SideNav from "./SideNav";
import NavLinks from "./NavLinks";
import SocialLinks from "./SocialLinks";
import { SideBarInterface, SideBarEntity } from "./types";
import "./styles.scss";

interface Props {
  isActive: boolean;
  toggle: () => void;
}

const Sidebar: FC<Props> = React.memo(({ isActive, toggle }: Props) => {
  const {
    name,
    email,
    phone,
    location,
    sideBar: { title, sideBarText },
  }: SideBarInterface = (jsonData as unknown) as SideBarInterface;

  return (
    <div data-testid="sidebar-wrap">
      <Particles />
      <div className="sidebar-info-wrap">
        <div className="sidebar-info-main">
          <div className="profile-wrap">
            <img src={vasanthImg} alt="vkdev" className="vertical-center" />
          </div>
          <div className="hamburger-wrap">
            <HamBurgerButton isActive={isActive} toggle={toggle} />
          </div>
          <div className="theme-wrap">
            <DarkModeToggleButton />
          </div>
          <div className="profile-info-wrap">
            <div>
              <h3 className="profile-name">{name || "N/A"}</h3>
            </div>
            <div>
              <h4>{title || "N/A"}</h4>
            </div>
            <div>
              {sideBarText &&
                sideBarText.length &&
                sideBarText.map((item: SideBarEntity) => (
                  <p key={item.id}>{item.text}</p>
                ))}
            </div>
            <div className="mobile-nav">
              <NavLinks toggle={toggle} />
            </div>
            <div>
              <span className="sidenav-icon-wrap">
                <FontAwesomeIcon icon={faEnvelope} size="sm" />
              </span>
              <span>{email || "N/A"}</span>
            </div>
            {/* <div>
              <span className="sidenav-icon-wrap">
                <FontAwesomeIcon icon={faMobile} size="sm" />
              </span>
              <span> {phone || "N/A"}</span>
            </div> */}
            <div>
              <span className="sidenav-icon-wrap">
                <FontAwesomeIcon icon={faMapPin} size="sm" />
              </span>
              <span> {location || "N/A"}</span>
            </div>
          </div>
        </div>
        <div>
          <SideNav isActive={isActive} toggle={toggle} />
        </div>
        <div>
          <SocialLinks />
        </div>
      </div>
    </div>
  );
});

export default Sidebar;
