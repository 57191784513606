import ActionType from "../constant/ActionTypes";
import { BlogDispatchTypes, BlogsState } from "../types/Blog.actionsTypes";

const intialState = {
  loading: false,
  data: {},
  error: null,
};

const blog = (
  state: BlogsState = intialState,
  action: BlogDispatchTypes
): BlogsState => {
  switch (action.type) {
    case ActionType.FETCH_BLOG_REQUEST: {
      return {
        loading: true,
        data: {},
        error: null,
      };
    }
    case ActionType.FETCH_BLOG_SUCCESS: {
      return {
        loading: false,
        data: action.blog.entities.data,
        error: null,
      };
    }
    case ActionType.FETCH_BLOG_FAILURE: {
      return {
        loading: false,
        data: {},
        error: action.error,
      };
    }
    default: {
      return state;
    }
  }
};

export default blog;
