import React, { FC } from "react";
import { HamburgerSlider } from "react-animated-burgers";
import "./styles.scss";

interface Props {
  isActive: boolean;
  toggle: () => void;
}

const HamBurgerButton: FC<Props> = React.memo(({ isActive, toggle }: Props) => {
  return (
    <HamburgerSlider
      isActive={isActive}
      buttonStyle={{ outline: "0px" }}
      className="hamburger-btn"
      toggleButton={toggle}
    />
  );
});

export default HamBurgerButton;
