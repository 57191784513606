import React, { FC } from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fab } from "@fortawesome/free-brands-svg-icons";
import jsonData from "../../../../constant.json";
import "./SocialLinks.scss";

library.add(fab);

interface SocialLinksInterface {
  SocialLinks: {
    LinkedIn: string;
    Github: string;
  };
}

const SocialLinks: FC = () => {
  const {
    SocialLinks: { LinkedIn, Github },
  }: SocialLinksInterface = (jsonData as unknown) as SocialLinksInterface;
  return (
    <ul className="clearfix social-links">
      <li>
        <a href={LinkedIn} rel="noreferrer" target="_blank">
          <FontAwesomeIcon
            icon={["fab", "linkedin"]}
            color="#ffffff"
            size="lg"
          />
        </a>
      </li>
      <li>
        <a href={Github} rel="noreferrer" target="_blank">
          <FontAwesomeIcon icon={["fab", "github"]} color="#ffffff" size="lg" />
        </a>
      </li>
    </ul>
  );
};

export default SocialLinks;
