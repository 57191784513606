import React, { FC, useCallback, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import HamBurgerButton from "../../../atoms/HamBurgerButton";
import Routes from "../routes";
import Sidebar from "../sidebar";

import "./styles.scss";

const Layout: FC = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleButton = useCallback(
    () => setIsActive((prevState) => !prevState),
    []
  );
  return (
    <React.StrictMode>
      <Router>
        <div className="page-wrap" data-testid="page-wrap">
          <div
            className={`sidebar-wrap ${
              isActive
                ? `mobile-sidebar mobile-sidebar-active`
                : `mobile-sidebar mobile-sidebar-close`
            }`}
          >
            <Sidebar isActive={isActive} toggle={toggleButton} />
          </div>
          <div className="main-page-wrap">
            <div className="mobile-side-bar">
              <HamBurgerButton isActive={isActive} toggle={toggleButton} />
            </div>
            <Routes />
          </div>
        </div>
      </Router>
    </React.StrictMode>
  );
};

export default Layout;
