import Axios, { AxiosResponse } from "axios";
import { BlogEntity, BlogDataI } from "./Types";
import { sentryCatchError } from "../../shared/Sentry-utils/senetry-utils";

const mapBlogListApiToModel = ({
  data,
}: AxiosResponse<BlogDataI>): BlogEntity[] => {
  const {
    blogdata: { blogdata },
  }: BlogDataI = data;
  return blogdata?.map((blgdata: BlogEntity) => ({
    id: blgdata?.id,
    title: blgdata?.title,
    content: blgdata?.content,
    userid: blgdata?.userid,
  }));
};

const getBlogData = async (): Promise<BlogEntity[]> => {
  const promise = await new Promise<BlogEntity[]>((resolve, reject) => {
    try {
      Axios.get<BlogDataI>(`${process.env.REACT_APP_DEV_URL}/blog/getblog`)
        .then((response) => resolve(mapBlogListApiToModel(response)))
        .catch((error) => {
          if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
            sentryCatchError(error);
          }
          return reject(error);
        });
    } catch (error) {
      if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
        sentryCatchError(error);
      }
      reject(error);
    }
  });
  return promise;
};

export default getBlogData;
