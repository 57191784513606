import ActionType from "../constant/ActionTypes";

import {
  BlogNormalizerResult,
  BlogRequest,
  BlogSuccess,
  BlogFailure,
} from "../types/Blog.actionsTypes";

export const fetchBlogDataRequest = (): BlogRequest => ({
  type: ActionType.FETCH_BLOG_REQUEST,
});

export const fetchBlogDataSuccess = (
  blog: BlogNormalizerResult
): BlogSuccess => ({
  type: ActionType.FETCH_BLOG_SUCCESS,
  blog,
});

export const fetchBlogDataFailure = (error: string): BlogFailure => ({
  type: ActionType.FETCH_BLOG_FAILURE,
  error,
});
