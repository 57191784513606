import * as Sentry from "@sentry/browser";
import { CaptureConsole } from "@sentry/integrations";
import { Integrations as TracingIntegrations } from "@sentry/tracing";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function setupSentry() {
  if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [
        new CaptureConsole({
          levels: ["error"],
        }),
        new TracingIntegrations.BrowserTracing(),
      ],
      tracesSampleRate: 1.0,
      debug: true,
    });
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function sentryCatchError(err: any) {
  const error = err.response || err;
  if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
    Sentry.captureException(error);
  }
}
