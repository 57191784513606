import React, { FC } from "react";
import DarkModeToggle from "react-dark-mode-toggle";
import useDarkMode from "use-dark-mode";

const DarkModeToggleButton: FC = () => {
  const darkMode = useDarkMode(false);
  return (
    <div>
      <DarkModeToggle
        checked={darkMode.value}
        onChange={darkMode.toggle}
        size={60}
        speed={3}
      />
    </div>
  );
};

export default DarkModeToggleButton;
