import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import Loader from "../../../atoms/Loader";

const HomePage = lazy(() => import("../../../pages/Home"));
const AboutPage = lazy(() => import("../../../pages/About"));
const BlogPage = lazy(() => import("../../../pages/Blog"));
const ProjectPage = lazy(() => import("../../../pages/Project"));
const ContactPage = lazy(() => import("../../../pages/Contact"));
const ErrorPage = lazy(() => import("../../../pages/Error"));

const Routes = (): React.ReactElement => {
  const routesArray = [
    {
      id: 1,
      path: "/",
      component: HomePage,
    },
    {
      id: 2,
      path: "/about",
      component: AboutPage,
    },
    {
      id: 3,
      path: "/project",
      component: ProjectPage,
    },
    {
      id: 4,
      path: "/contact",
      component: ContactPage,
    },
    {
      id: 5,
      path: "/blog",
      component: BlogPage,
    },
    {
      id: 6,
      path: "*",
      component: ErrorPage,
    },
  ];
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routesArray.map((data) => {
          const { id, path, component } = data;
          return <Route key={id} path={path} exact component={component} />;
        })}
      </Switch>
    </Suspense>
  );
};

export default Routes;
